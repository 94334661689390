import React, { useState } from "react";
import { db } from "./firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import logo from "./assets/logo.png";
import mnemonicsImage from "./assets/1.3 - mnemonics.png";
import spellsImage from "./assets/1.4 - Spells.png";
import mapImage from "./assets/3.1 Default Map.png";
import hanziCollectionImage from "./assets/5.1 Hanzi Collection.png";
import spellsCollectionImage from "./assets/6.1 Spells Collection.png";

const LexillLandingPage = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "waitlist"), {
        email: email,
        timestamp: serverTimestamp(),
      });

      alert("Thanks for joining the waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error submitting your email");
    }
  };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img src={logo} alt="Lexill Logo" className="w-10 h-10 mr-2" />
              <span className="text-2xl font-bold text-[#6981F4]">Lexill</span>
            </div>
            <div className="space-x-4">
              <a
                href="#features"
                className="text-gray-600 hover:text-[#6981F4]"
              >
                Features
              </a>
              <a href="#about" className="text-gray-600 hover:text-[#6981F4]">
                About
              </a>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Master Mandarin with</span>
              <span className="block text-[#6981F4]">Lexill</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Learn Chinese characters through engaging mnemonics and
              Pokémon-inspired gameplay. Collect Hanzi, cast spells, and embark
              on a linguistic adventure in Lexiland!
            </p>
            <div className="mt-10 sm:flex sm:justify-center">
              <form onSubmit={handleSubmit} className="sm:flex">
                <input
                  type="email"
                  required
                  placeholder="Enter your email"
                  className="w-full px-5 py-3 border border-gray-300 shadow-sm placeholder-gray-400 focus:ring-1 focus:ring-[#6981F4] focus:border-[#6981F4] sm:max-w-xs rounded-md"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#6981F4] hover:bg-[#5A6FD6] md:py-4 md:text-lg md:px-10"
                  >
                    Join Waitlist
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section id="features" className="bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
              Key Features
            </h2>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <FeatureCard
                image={mnemonicsImage}
                title="Mnemonic Learning"
                description="Use engaging mnemonics to remember Chinese characters easily and effectively."
              />
              <FeatureCard
                image={hanziCollectionImage}
                title="Capture Hanzi"
                description="Answer questions to capture Chinese characters, just like catching Pokémon!"
              />
              <FeatureCard
                image={spellsCollectionImage}
                title="Spells Collection"
                description="Learn and practice full sentences by collecting 'spells' in various chapters."
              />
            </div>
          </div>
        </section>

        <section id="about" className="bg-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="lg:w-1/2">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                  About Lexill
                </h2>
                <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                  Lexill combines the power of mnemonics with gamification to
                  make learning Mandarin Chinese fun and effective. Capture
                  Hanzi, build your collection, and cast spells to master the
                  language!
                </p>
              </div>
              <div className="mt-8 lg:mt-0 lg:w-1/2">
                <div className="flex items-center justify-center lg:justify-end">
                  <img
                    className="w-full max-w-md rounded-lg shadow-xl"
                    src={spellsImage}
                    alt="Lexill app preview"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
              Explore Lexiland
            </h2>
            <div className="flex flex-col items-center">
              <img
                src={mapImage}
                alt="Lexill gameplay"
                className="w-full max-w-2xl mb-8 rounded-lg shadow-xl"
              />
              <p className="text-center text-gray-600 max-w-2xl">
                Start your journey in Lexiland, answer questions to capture
                Hanzi, and progress through chapters to learn new vocabulary and
                sentences. Track your progress with HP for each Hanzi and unlock
                new areas as you advance!
              </p>
            </div>
          </div>
        </section>
      </main>

      {/* New App Store Coming Soon section */}
      <section className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold sm:text-4xl mb-4">
              Coming Soon to Your Device
            </h2>
            <p className="text-xl mb-8">
              Lexill will be available on iOS and Android. Join the waitlist to
              be notified when we launch!
            </p>
            <div className="flex justify-center space-x-4">
              <AppStoreBadge store="app-store" />
              <AppStoreBadge store="play-store" />
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <p className="text-center text-base text-gray-400">
            &copy; 2023 Lexill. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({
  image,
  title,
  description,
}: {
  image: string;
  title: string;
  description: string;
}) => (
  <div className="flex flex-col items-center text-center bg-gray-50 p-6 rounded-lg shadow-md">
    <div className="w-full h-64 mb-4 overflow-hidden rounded-md">
      <img src={image} alt={title} className="w-full h-full object-contain" />
    </div>
    <h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
    <p className="text-base text-gray-500">{description}</p>
  </div>
);

const AppStoreBadge = ({ store }: { store: "app-store" | "play-store" }) => (
  <div className="w-40 h-12 bg-black rounded-lg flex items-center justify-center cursor-not-allowed opacity-50">
    <span className="text-white font-semibold">
      {store === "app-store" ? "App Store" : "Google Play"}
    </span>
  </div>
);

export default LexillLandingPage;
