// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBOGH1thbL0NrdxqlxKWW_WnVrq5Uu0mIA",
  authDomain: "lexill.firebaseapp.com",
  projectId: "lexill",
  storageBucket: "lexill.appspot.com",
  messagingSenderId: "806004132826",
  appId: "1:806004132826:web:af3df46c9cd49fea24cee3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
